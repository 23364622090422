import React from 'react';
import LogoSvg from '../../resources/svg/logo.svg';

function Logo() {
   return (
      <div className='logo'>
         <img className='logo__img' src={LogoSvg} alt='logo' />
      </div>
   );
}

export default Logo;
