import React from 'react';

function PhoneLogin() {
   return (
      <div className='phone'>
         <div className='phone__heading'>Your Phone Number</div>
         <div className='phone__input-container'>
            <input className='phone__input' type='text' placeholder='+1 ▾ 111-222-3333' />
            <button className='phone__btn' onClick={(e) => console.log(e.target)}>
               Get OTP
            </button>
         </div>
      </div>
   );
}

export default PhoneLogin;
