import React from 'react';
import Logo from '../logo/Logo';
import PolicyText from '../text/PolicyText';
import SubmitText from '../text/SubmitText';
import LoginContainer from './LoginContainer';
import PhoneLogin from './PhoneLogin';

function CtaLogin() {
   return (
      <div className='cta-login-container'>
         <div className='cta-login'>
            <Logo />
            <LoginContainer />
            <PhoneLogin />
            <SubmitText />
            <PolicyText />
         </div>
      </div>
   );
}

export default CtaLogin;
