import React from 'react';

function SubmitText() {
   return (
      <div className='info mg-btm-lg info--split'>
         <span className='info__text'>New to SeduxLab? </span>
         <span className='info__text info__text--highlight'>
            <a href='submit'>Submit your member application today.</a>
         </span>
      </div>
   );
}

export default SubmitText;
