import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import mainCouple from '../../resources/images/main-couple.png';
import straightCouple from '../../resources/images/straight-couple.png';
import lesbianCouple from '../../resources/images/lesbian-couple.png';

function CtaImageCarosel() {
   return (
      <div className='cta-carosel-container'>
         <div className='cta-carosel'>
            {/* <div>
               <img
                  className='cta-carosel__img'
                  src={mainCouple}
                  alt='couples holding each other'
               />
            </div> */}
            <Carousel
               autoPlay
               showArrows={false}
               infiniteLoop={true}
               showStatus={false}
               showThumbs={false}
               stopOnHover={true}
               swipeable={false}
            >
               <div>
                  <img
                     className='cta-carosel__img'
                     src={mainCouple}
                     alt='couples holding each other'
                  />
               </div>
               <div>
                  <img
                     className='cta-carosel__img'
                     src={straightCouple}
                     alt='couples holding each other'
                  />
               </div>
               <div>
                  <img
                     className='cta-carosel__img'
                     src={lesbianCouple}
                     alt='couples holding each other'
                  />
               </div>
            </Carousel>
         </div>
      </div>
   );
}

export default CtaImageCarosel;
