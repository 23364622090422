import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import FacebookLogo from '../../resources/svg/facebook.svg';

function Facebook() {
   const responseFacebook = async (response) => {
      console.log(response);
   };

   return (
      <div>
         <FacebookLogin
            appId='514329753141740'
            autoLoad={false}
            callback={responseFacebook}
            fields='name,email,picture,id'
            render={(renderProps) => (
               <button onClick={renderProps.onClick} className='social-media-button'>
                  <span>
                     <img className='social-icon' src={FacebookLogo} alt='facebook logo' />
                  </span>
                  <span className='social-media-text'>Login with Facebook</span>
               </button>
            )}
         />
      </div>
   );
}

export default Facebook;
