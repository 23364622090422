import React from 'react';
import GoogleLogo from '../../resources/svg/google.svg';
import { GoogleLogin } from 'react-google-login';

function Google() {
   const responseGoogle = async (response) => {
      console.log(response);
   };

   return (
      <div>
         <GoogleLogin
            clientId='525109861584-bqn1jreos9alt7dm7p47v16fi3c1962k.apps.googleusercontent.com'
            render={(renderProps) => (
               <button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className='social-media-button'
               >
                  <span>
                     <img className='social-icon' src={GoogleLogo} alt='google logo' />
                  </span>
                  <span className='social-media-text'>Login with Google</span>
               </button>
            )}
            buttonText='Login'
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
         />
      </div>
   );
}

export default Google;
