import React from 'react';

function PolicyText() {
   return (
      <div className='info line-height-lg  mg-btm-sml'>
         <div className='info__text'>By continuing, you’re confirming that you’ve read</div>
         <div className='info__text'>
            and agree to our
            <span> </span>
            <span className='info__text info__text--highlight'>
               <a href='terms'>Terms and Conditions & Privacy Policy</a>
            </span>
         </div>
      </div>
   );
}

export default PolicyText;
