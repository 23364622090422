import React from 'react';

function Email() {
   return (
      <div>
         <button className='social-media-button'>
            <span className='social-media-text'>Login with Email</span>
         </button>
      </div>
   );
}

export default Email;
