import React from 'react';
import CtaImageCarosel from '../components/cta-image-carosel/CtaImageCarosel';
import CtaLogin from '../components/cta-login/CtaLogin';

function LandingPage() {
   return (
      <div className='landing-page'>
         <CtaImageCarosel  />
         <CtaLogin  />
      </div>
   );
}

export default LandingPage;
