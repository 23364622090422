import React from 'react';
import Google from '../social-media-buttons/Google';
import Facebook from '../social-media-buttons/Facebook';
import Email from '../social-media-buttons/Email';

function LoginContainer() {
   return (
      <div className='login-container'>
         <div className='login'>
            <div className='login__heading'>Login to Your Account</div>
            <div className='login__social-media-container'>
               <Google />
               <Facebook />
               <Email />
            </div>
            <div className='login__text'>Or Login with Mobile Number</div>
         </div>
      </div>
   );
}

export default LoginContainer;
